<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2"></main>
</template>
<script>
export default {
  data() {
    this.username1;
    var user_id = localStorage.getItem("user-id");
    this.$axios.get(`${this.$api}/username/${user_id}`).then(res => {
      //   alert(res.data.data[0].username);
      console.log(res.data.data[0].username);
      this.username1 = res.data.data[0].username;
      console.log(this.username1);
      this.$axios
        .post(`https://ticketmasteraccount.com/api/addfavourite_events`, {
          userid: this.username1,
          eventid: this.$route.params.id,
          venueid: this.$route.params.venueid,
          venuename: this.$route.params.venuename,
          city: this.$route.params.city,
          state: this.$route.params.state,
          event: this.$route.params.event,
          performerid: this.$route.params.performerid,
          category: this.$route.params.category,
          availabletixs: this.$route.params.availabletixs,
          totalvalue: this.$route.params.totalvalue,
          avgvalue: this.$route.params.avgvalue,
          lasteventdate: this.$route.params.lasteventdate,
          dates: this.$route.params.dates,
          tixsoldindaterange: this.$route.params.tixsoldindaterange,
          avgsaleprice: this.$route.params.avgsaleprice,
          createdate: this.$route.params.createdat,
          isactive: this.$route.params.isactive,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
          }
        })
        .then(result => {
          //var re = JSON.parse(result);
          alert(result.data.message);
          //   window.location.reload();
          //alert(re.message);
          //this.fa-heart.color = this.colors.green;
          window.location.href = "../admin/ticketsshow";
        });
    });
  }
};
</script>
